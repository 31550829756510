import React from 'react'
import { Container, Divider, Grow, Grid } from '@mui/material'
import PersonDetailCard from '../components/PersonDetailCard'
import {Helmet} from "react-helmet";

function createData(name, photo, position, link, detail) {
    return { name, photo, position, link, detail };
}

const titleName = "BrainNN22 - Speakers"

const rows = [
    createData('Lifang He', '/people/LifangHe.jpg', 'Lehigh University',
        'https://engineering.lehigh.edu/faculty/lifang-he',
        'Dr. Lifang He is an Assistant Professor in Lehigh University. She received the B.S. degree in Computational Mathematics from ' +
        'Northwest Normal University, and the Ph.D. degree in Computer Science from South China University of Technology. ' +
        "Before joining Lehigh's faculty, she was a postdoctoral associate in the Department of Biostatistics, Epidemiology and Informatics" +
        'within the Perelman School of Medicine at University of Pennsylvania, as well as the Weill Cornell Medical College of Cornell University. ' +
        'Her research interests include machine learning/deep learning, data mining and tensor analysis, with applications in ' +
        'brain connectome analysis.'),
    createData('Archana Venkataraman', '/people/ArchanaVenkataraman.png', 'Johns Hopkins University',
        'https://engineering.jhu.edu/faculty/archana-venkataraman/',
        'Dr. Archana Venkataraman is a John C. Malone Assistant Professor in the Department of Electrical and ' +
        'Computer Engineering at Johns Hopkins University. She directs the Neural Systems Analysis Laboratory and is ' +
        'a core faculty member of the Malone Center for Engineering in Healthcare and the Mathematical Institute for ' +
        'Data Science. Dr. Venkataraman’s research lies at the intersection of artificial intelligence, network ' +
        'modeling and clinical neuroscience. Her work has yielded novel insights in to debilitating neurological ' +
        'disorders, such as autism, schizophrenia, and epilepsy, with the long-term goal of improving patient care. ' +
        'Dr. Venkataraman completed her B.S., M.Eng. and Ph.D. in Electrical Engineering at MIT in 2006, 2007 and 2012, ' +
        'respectively. She is a recipient of the MIT Provost Presidential Fellowship, the Siebel Scholarship, the ' +
        'National Defense Science and Engineering Graduate Fellowship, the NIH Advanced Multimodal Neuroimaging ' +
        'Training Grant, the CHDI Grant on network models for Huntington\'s Disease, numerous best paper ' +
        'awards, and the National Science Foundation CAREER award. Dr. Venkataraman was also named by MIT ' +
        'Technology Review as one of 35 Innovators Under 35 in 2019.'),
    createData('Liang Zhan', '/people/LiangZhan.jpeg', 'University of Pittsburgh',
        'https://www.engineering.pitt.edu/people/faculty/liang-zhan/',
        'Dr. Liang Zhan is an Associate Professor in the Department of Electrical & Computer Engineering and ' +
        'Bioengineering at University of Pittsburgh, where he also serves as the associate director of the Pittsburgh ' +
        'Center for Artificial Intelligence Innovation in Medical Imaging (CAIIMI).  His research areas include brain ' +
        'connectomics and data mining, as well as clinical/translational research on brain diseases, such as Alzheimer’s ' +
        'disease, Parkinson’s disease, bipolar disorder, depression, and Traumatic Brain Injury, etc.  He has extensive ' +
        'experience with graph neural network models for brain network data.  He received his PhD from University of ' +
        'California, Los Angeles (UCLA) in 2011. Besides NSF Career award, his research is supported by NIH R21, R01s, ' +
        'U01, NSF IIS, and OIS, as well as US Department of Veterans Affairs.'
    ),
    createData('Ying Guo', '/people/YingGuo.jpg', 'Emory University',
        'https://sph.emory.edu/faculty/profile/index.php?FID=ying-guo-629',
        'Ying Guo is Professor in the Department of Biostatistics and Bioinformatics at Emory University, ' +
        'an appointed Graduate Faculty of the Emory Neuroscience Program and an Associate Faculty in Emory ' +
        'Department of Computer Science. She is a Founding Member and current Director of the Center for ' +
        'Biomedical Imaging Statistics (CBIS) at Emory University.  Dr. Guo’s research focus on developing ' +
        'analytical methods for neuroimaging and mental health studies. Her main research areas include ' +
        'statistical modeling and learning for agreement and reproducibility studies, brain network analysis, ' +
        'multimodal neuroimaging, hierarchical modeling and imaging-based prediction. Dr. Guo has served on the ' +
        'Editorial Boards of Biometrics, Statistics in Biosciences and Psychosomatic Medicine. She is a Fellow of ' +
        'American Statistical Association (ASA) and Chair-Elect of the ASA Statistics in Imaging Section. Dr. Guo ' +
        'has served as the principal investigator on several NIH R01 awards and she is a Standing Member of NIH ' +
        'Emerging Imaging Technologies in Neuroscience (EITN) Study Section. Dr. Guo was the Principal Organizer ' +
        'of The Statistical Methods in Imaging (SMI) 2021 Conference and has also served on the organizing ' +
        'committees of several national and international conferences.'),
    createData('Yize Zhao', '/people/YizeZhao.png', 'Yale University',
        'https://ysph.yale.edu/profile/yize_zhao/',
        'Dr. Yize Zhao is an Associate Professor in the Department of Biostatistics, Yale School of Public Health, ' +
        'Yale University. She is also affiliated with Yale Center for Analytical Sciences, Yale Alzheimer\'s Disease Research ' +
        'Center, and Yale Computational Biology and Bioinformatics. Dr. Zhao\'s methodological research focuses on the ' +
        'development of statistical and machine learning methods to analyze large-scale complex data (neuroimaging, -omics, ' +
        'EHRs), Bayesian methods, feature selection, predictive modeling, data integration, missing data and network analysis. ' +
        'Her most recent research agenda includes analytical method developments and applications on brain network analyses, ' +
        'multimodal imaging modeling, imaging genetics, and the integration of biomedical data with EHR data. She also has ' +
        'strong interests in subject matter fields including aging, mental health and cancer.'
    ),
    createData('Ehsan Adeli', '/people/EhsanAdeli.jpg', 'Stanford University',
        'https://stanford.edu/~eadeli/',
        'Dr. Ehsan Adeli is an Assistant Professor at Stanford University, School of Medicine, Department of ' +
        'Psychiatry and Behavioral Sciences, Computational Neuroscience (CNS) Lab. He is also affiliated with the ' +
        'Computer Science Department, Stanford AI Lab (SAIL), Stanford Vision and Learning (SVL), and the Stanford ' +
        'Partnership in AI-Assisted Care (PAC). His research interests include computer vision, computational ' +
        'neuroscience, medical image analysis, and biomedical data science for healthcare. Dr. Adeli is an Executive ' +
        'Co-Director of Stanford AGILE Consortium (Advancing technoloGy for fraIlty & LongEvity), and a faculty member ' +
        'of Stanford Wu Tsai Neurosciences Institute, Stanford Institute for Human-Centered AI, and Stanford Center for ' +
        'AI in Medical Imaging. He is an Associate Editor of IEEE Journal of Biomedical and Health Informatics and the ' +
        'Journal of Ambient Intelligence and Smart Environments. He is a Senior Member of IEEE and has recently served ' +
        'as area chair or associate editor for several top conferences.'
    ),
    createData('Carl Yang', '/people/CarlYang.jpg', 'Emory University',
        'https://cs.emory.edu/~jyang71/',
        'Dr. Carl Yang is an Assistant Professor in Emory University. He received his Ph.D. in Computer Science ' + 
        'at University of Illinois, Urbana-Champaign and B.Eng. in Computer Science and Engineering at Zhejiang University. ' +
        'His research interests span graph data mining, applied machine learning, knowledge graphs and federated learning, ' + 
        'with applications in recommender systems, biomedical informatics,  neuroscience and healthcare. ' + 
        "Carl's research results have been published in top venues like TKDE, KDD, WWW, NeurIPS, ICML, ICLR, ICDE, SIGIR and ICDM. " +
        'He also received the Dissertation Completion Fellowship of UIUC in 2020, the Best Paper Award of ICDM in 2020, ' + 
        'the Best Paper Award of KDD Health Day in 2022, the Outstanding Paper Award of ML4H in 2022, ' +
        'the Amazon Research Award and multiple Emory internal research awards.')
    // createData('Xiaoxiao Li', '/people/XiaoxiaoLi.jpg', 'University of British Columbia',
    //     'https://xxlya.github.io/xiaoxiao/',
    //     'Dr. Xiaoxiao Li is an Assistant Professor in the Electrical and Computer Engineering Department at University of British Columbia (UBC), Canada. ' +
    //     'Before joining UBC, she was a Postdoc Research Fellow in the Computer Science Department at Princeton University. ' +
    //     'Dr. Li obtained her Ph.D. degree from Yale University in 2020. Her research interests range across the interdisciplinary fields of ' +
    //     'deep learning and biomedical data analysis, aiming to improve the trustworthiness of AI systems for healthcare. ' +
    //     'In the recent few years，Dr. Li has over 30 papers published in leading machine learning conferences and journals, including NeurIPS, ' +
    //     'ICML, ICLR, MICCAI, IPMI, BMVC, and Medical Image Analysis. Her work has been recognized with the OHBM Merit Abstract Award, the MIML ' +
    //     'Best Paper Award, and the DART Best Paper Award.')
]

const TeamPage = () => {
  return (
    <Container style={
        {
            alignItems: 'center',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        }
    }>
        <Helmet>
            <title>{titleName}</title>
        </Helmet>
        <h1 style={{padding: '40px'}}>Speakers</h1>
        {/*  Horizontal align PersonCard */}

        <Divider style={
            {
                width: '100%',
                marginBottom: '50px',
            }
        }/>



            <Grid container spacing={1} justifyContent={'center'}>
                {rows.map((row) => (
                    <Grow
                        in={true}
                        style={{ transformOrigin: '0 0 0' }}
                        // style={{transitionDelay: 500 * index + 'ms'}}
                    >
                        <Grid>
                            <PersonDetailCard
                                key={row.name}
                                name={row.name}
                                photo={row.photo}
                                position={row.position}
                                link={row.link}
                                detail={row.detail}
                            />
                        </Grid>
                    </Grow>
                ))}
            </Grid>

    </Container>
  )
}

export default TeamPage
