import {
    Paper, Avatar, Link, Grid
} from '@mui/material'
import React from "react";
// import {blue} from "@mui/material/colors";

// React Component: PersonDetailCard
// Type: Component
// Description:
// 		A card that displays a person's information with descriptions

export default class PersonDetailCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            photo: this.props.photo,
            name: this.props.name,
            position: this.props.position,
            link: this.props.link,
            title: this.props.title,
            detail: this.props.detail,
        }
    }

    render() {
        return (
            <Paper style={{
                margin: '10px',
                padding: '10px',
                maxWidth: '1280px',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}>
                {/* Round profile photo */}
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    display="flex"
                    >
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        display="flex"
                        xs={12}
                        sm={5}
                    >
                        <Avatar
                            src={this.state.photo}
                            sx={{
                                width: '150px',
                                height: '150px',
                                fontSize: '70px',
                                bgcolor: '#1e376d',
                            }}
                            imgProps={{
                                sx: {
                                    marginBottom: '0px',  // Fix the bottom margin = 1.16 rem bug
                                }
                            }}
                        >
                        {/*    First letter of the first name and last name, font size 20 */}
                            {this.state.name.split(' ')[0][0] + this.state.name.split(' ')[1][0]}
                        </Avatar>
                        {/* Add spacings */}
                        <div style={{
                            marginTop: '30px',
                            marginBottom: '10px',
                        }}/>
                        {/* Name with link */}
                        <Link href={this.state.link} target="_blank" rel="noreferrer">
                            <h2>{this.state.name}</h2>
                        </Link>
                        {/*Add title if it is given*/}
                        {this.state.title ? <p style={{marginBottom: "0px"}}>{this.state.title}</p> : null}
                        <p align="center">{this.state.position}</p>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={7}
                    >
                        <p style={{margin: '20px', textAlign: "justify"}}>{this.props.detail}</p>
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}

